import React from 'react';
import styled from "styled-components";
import { Link } from "gatsby";
import { truncateText } from "../../utils/text"

const BlogCardsListing = ({blogCards}) => {
    
    return (
        <WrapperListing>
            <ContanierCards>
                {
                    blogCards?.map((card,index) => {
                        const cardText = truncateText(card.node.data.body[0].primary.text[0].text,200)
                        console.log(card.node.tags)
                        return (

                            <CardWrapper key={index}>
                                <Link to={`/blog/${card.node.uid}`}>
                                    <CardListing>
                                            <CardCover>
                                                <img  src={card.node.data.featured_image.url} alt={card.node.uid}/>
                                            </CardCover>
                                            <CardBody>
                                                <TagsList>
                                                    {
                                                        card.node.tags.map((tag,index) =>{
                                                            return (
                                                                <Tag key={index}>
                                                                    {tag}
                                                                </Tag>
                                                            )
                                                        })
                                                    }
                                                </TagsList>
                                                <PostTitle>
                                                    {card.node.data.post_title[0].text}
                                                </PostTitle>
                                                <PostText>
                                                    {cardText}
                                                </PostText>
                                                <FooterCard>
                                                    <span>
                                                        {card.node.data.date}
                                                    </span>
                                                </FooterCard>
                                            </CardBody>
                                    </CardListing>
                                </Link>
                            </CardWrapper> 
                        )
                    })
                }
            </ContanierCards>
        </WrapperListing>
    );
}


const WrapperListing = styled.div`
    max-width: 1200px;
    width:100%;
    margin:80px auto;
    height:auto;
`

const ContanierCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content:center;
    width:100%;
    @media (max-width:1280px) {
        margin:0 auto;
    }
    @media (max-width:768px) {
       margin:0 auto;
    }
`
const CardWrapper = styled.div`
    flex: 0 0 47%;
    max-width: 47%;
    padding-right: 15px;
    padding-left: 15px;
    a {
        text-decoration:none;
    }
    @media (max-width:1280px) {
        margin: 0;
        padding-bottom: 0;
        flex: 0 0 42%;
        max-width: 42%;
    }
    @media (max-width:600px) {
        max-width:70%;
        flex: 0 0 70%;
        padding:0;
    }
    @media (max-width:470px) {
        max-width:90%;
        flex: 0 0 90%;
    }
`
const CardListing = styled.div`
    margin-bottom: 40px;
    height: calc(100% - 30px);
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,.1);
    position: relative;
    transition: all .55s ease;
    :hover {
        box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    }
`

const CardCover = styled.div`
  margin-bottom: -6px;
  min-height:320px;
  display:flex;
  img {
    width: 100%;
    height: 320px;
    flex-grow: 1;
    object-fit: cover;
    object-position: center top;
  }
`;

const CardBody = styled.div`
    padding: 20px;
    display:flex;
    flex-direction:column;
    position:relative;
`
const PostTitle = styled.h5`
    color: #2d2d2d;
    font-family: Poppins, sans-serif;
    text-decoration:none;
    font-size: 20px;
    font-weight: 500;
    margin:8px 0;
    height:60px;
    @media (max-width:768px) {
        height:90px;
    }
    @media (max-width:470px) {
        height:auto;
    }
`

const PostText = styled.p`
    color: #2d2d2d;
    font-family: Poppins, sans-serif;
    font-size:14px;
    line-height: 23px;
    margin-top:0;
    height:90px;
    @media (max-width:768px) {
        height:auto;
    }
`

const FooterCard = styled.div`
    display:flex;
    padding:12px 0;
    border-top:1px solid #2d2d2d;
    justify-content:flex-end;
    span {
        color: #333333;
        font-size:14px;
        line-height: 23px;
        opacity:.7;
    }
`

const TagsList = styled.div`
    display:flex;
    flex-wrap:wrap;
`

const Tag = styled.span`
    display:inline-block;
    padding:4px 8px;
    color:white;
    font-family: Poppins, sans-serif;
    font-size:12px;
    margin-right:5px;
    background-color: #008e8e;
    border-radius:8px;
    margin-bottom:4px;
    text-transform:capitalize;
`


export default BlogCardsListing;