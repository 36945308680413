import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderInfoPage from "../components/HeaderInfoPages/HeaderInfoPages";
import CategoryList from "../components/CategoryList/CategoryList";
import BlogCardsListing from "../components/BlogCardsListing/BlogCardsListing";
import Pagination from "../components/Pagination/Pagination";
const BlogCategoryListing = props => {
  const cardsListing = props.data.allPrismicBlogPost.edges
  const categories = props.pageContext.categoryList
  const currentCat = props.pageContext.category

  return (
    <Layout>
      <SEO title="BlogCategoryListinge" />
      <HeaderInfoPage title={`${currentCat}`} />
      <CategoryList 
        listingType="blog"
        categories={categories}
      />
      <BlogCardsListing blogCards={cardsListing} />
      <Pagination 
        numPages={props.pageContext.pressNumPages}
        currentPage={props.pageContext.currentPage}
        slugPrefix={`/blog/${currentCat}`}
      />
    </Layout>
  )
}


export default BlogCategoryListing;


export const BlogCatListingPageQuery = graphql`
  query BlogCatListingPageQuery($skip: Int!, $limit: Int!,$category: String!) {
    allPrismicBlogPost(
      skip: $skip, 
      limit: $limit,
      sort: {fields: data___date, order: DESC},
      filter: {data: {category: {elemMatch: {text: {eq: $category}}}}}
      ) {
      edges {
        node {
          uid
          tags
          data {
            date
            featured_image {
              url
            }
            post_title {
              text
            }
            category {
              text
            }
            body {
              primary {
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`