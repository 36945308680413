import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const CategoryList = ({
  categories,
  listingType,
}) => {
  return (

    <CategoryContent>
      <ScrollWrapper>
        <CategoryItem
          to={`/${listingType}`}
          activeClassName="active"
        >
          Show all
        </CategoryItem>
        {categories.map(category => {
          const slug = `${(category).replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase()}`
          return (
            category.toLowerCase() !== "uncategorized" && (
              <CategoryItem
                key={category.toLowerCase()}
                partiallyActive={true}
                to={`/${listingType}/${slug}`}
                activeClassName="active"
              >
                {category}
              </CategoryItem>
            )
          )
        })}
      </ScrollWrapper>
    </CategoryContent>
  )
}

export default CategoryList

CategoryList.propTypes = {

}

CategoryList.defaultProps = {

}


export const CategoryContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top:16px;
  max-width: 1200px;
  width:100%;
  margin:40px auto;
  @media (max-width:1280px) {
    padding: 24px 30px;
    justify-content: space-between;
    max-width: 80%;
  }
  @media (max-width:768px) {
    padding: 16px 30px 0;
  }
  .active {
    color: #008e8e;
  }
  @media (max-width:470px) {
    width: 100%;
    height: 100%;
    padding: 0;
    justify-content: flex-start;
  }
`

export const CategoryItem = styled(Link)`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  animation: all 0.2s ease-in-out;
  margin: 0 20px;
  text-transform: capitalize;
  :hover {
    color: #008e8e;
  }
  @media (max-width:768px) {
    margin:0 32px 0 0;
    
  }
  @media (max-width:470px) {
    margin:0 24px 0 0;
  }
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (max-width:1280px) {
    max-width: 90%;
  }
  @media (max-width:768px) {
    margin: 0;
    padding-bottom: 0;
    flex-wrap:wrap;
  }
  @media (max-width:470px) {
    margin-top:30px;
    flex-direction:column;
    align-items:flex-start;
  }
`